import { CSSProperties } from 'react';
/* eslint-disable-next-line no-restricted-imports */
import styled, { css } from 'styled-components';

import { pxToRem } from 'Utils/cssUtils';

import colors from 'Static/typography/colors';

import { SwiperProps } from './interface';

export const Container = styled.div`
	position: relative;
	height: 100%;
	width: 100%;
`;

const BlurEffectStyle = css<Pick<SwiperProps, 'blurWidthInPx'>>`
	position: absolute;
	top: 0;
	bottom: 0;
	height: 100%;
	${props =>
		props.blurWidthInPx && `width: ${pxToRem(props.blurWidthInPx)}rem;`}
	content: '';
	background: linear-gradient(
		270deg,
		#ffffff 25%,
		rgba(255, 255, 255, 0) 100%
	);
	z-index: 2;
	display: none;
	pointer-events: none;
`;

export const SlidesWrapper = styled.div<
	Pick<SwiperProps, 'rtl' | 'slideWidth' | 'blurWidthInPx'> &
		CSSProperties & {
			$enableSnapScrolling: boolean;
			$hasRoundedCorners: boolean;
			$showBlurNearEdges: boolean;
			$showPrevBtn: boolean;
			$showNextBtn: boolean;
		}
>`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: ${props => (props.rtl ? 'row-reverse' : 'row')};
	overflow-x: scroll;
	/* Enable Safari touch scrolling physics which is needed for scroll snap */
	-webkit-overflow-scrolling: touch;
	white-space: nowrap;
	scrollbar-width: none;
	${({ $enableSnapScrolling }) =>
		$enableSnapScrolling && 'scroll-snap-type: x mandatory;'}

	${({ $hasRoundedCorners }) =>
		$hasRoundedCorners && 'overflow-y: hidden;  border-radius: 0.75rem;'}

	&::-webkit-scrollbar {
		display: none;
		height: 0;
	}
	& > :last-child {
		${props => {
			if (props.slideWidth === 'variable') {
				return {
					// IO was not firing for some reason,
					// hence adding this 1 px to allow slide to be fully visible to IO
					marginRight: '1px',
				};
			}

			return null;
		}}
	}

	&:before {
		${BlurEffectStyle};
		left: 0;
		background: linear-gradient(
			90deg,
			#ffffff 25%,
			rgba(255, 255, 255, 0) 100%
		);
	}

	&:after {
		${BlurEffectStyle};
		right: 0;
		background: linear-gradient(
			270deg,
			#ffffff 25%,
			rgba(255, 255, 255, 0) 100%
		);
	}

	${({ $showBlurNearEdges, $showPrevBtn }) =>
		$showBlurNearEdges &&
		$showPrevBtn &&
		`
		&:before {
			display: block;
		}
	`}

	${({ $showBlurNearEdges, $showNextBtn }) =>
		$showBlurNearEdges &&
		$showNextBtn &&
		`
		&:after {
			display: block;
		}
	`}
`;

export const Slide = styled.div<
	Pick<SwiperProps, 'transition' | 'slideWidth' | 'slidesToShow'> & {
		active: boolean;
		$enableSnapScrolling: boolean;
	}
>`
	${props =>
		props.$enableSnapScrolling
			? `
		scroll-snap-align: start;
		scroll-snap-stop: always;
	`
			: ''}
	${props => {
		let width =
			props.slideWidth === 'variable'
				? 'auto'
				: props.slideWidth ||
				  Math.floor(100 / (props.slidesToShow || 1)) + '%';
		return {
			width,
			minWidth: width,
		};
	}}
	display: flex;
	${props => {
		if (props.transition === 'fade-in') {
			return {
				top: 0,
				bottom: 0,
				right: 0,
				left: 0,
				position: 'absolute',
				opacity: props.active ? 1 : 0,
			};
		}

		return null;
	}}
	opacity: ${props =>
		props.transition === 'fade-in' && !props.active ? 0 : 1};

	& > :first-child {
		width: 100%;
	}
`;

export const PaginationDotsWrapper = styled.ul<
	Pick<SwiperProps, 'rtl' | 'paginationDotsPosX' | 'paginationDotsPosY'>
>`
	${props =>
		props.paginationDotsPosX
			? `left: ${props.paginationDotsPosX};`
			: `
		left: 0;
		right: 0;
	`}
	top: ${props => props.paginationDotsPosY || `calc(100% - 2.3438rem)`};
	display: flex;
	justify-content: center;
	flex-direction: ${props => (props.rtl ? 'row-reverse' : 'row')};
	position: absolute;
	margin: 0;
	padding: 0;
	list-style: none;
`;

export const PaginationDot = styled.li<{ isActive: boolean }>`
	transition: transform 0.5s;
	opacity: 0.5;
	width: 0.4688rem;
	height: 0.4688rem;
	margin-right: 0.4688rem;
	border-radius: 50%;
	background-color: ${colors.WHITE};
	${props => props.isActive && `opacity: 1;`}
`;
